<template>
<!-- 赛事活动 -->
    <div class="event">
        <div>
            <div class="posit-left">
                <listevents></listevents>
            </div>
            <div class="event-top">
                <eventcentertop style="margin-bottom:0.2rem"></eventcentertop>
                <numberevents></numberevents>
            </div>
            <div class="posit-right">
                <imageanalysis style="margin-bottom:0.18rem"></imageanalysis>
                <activetime></activetime>
            </div>
        </div>
        <div class="event-bottom">
            <eventbottom></eventbottom>
        </div>
    </div>
</template>

<script>

export default {
    components: {
        listevents: () => import('../charts/eventsone/listevents.vue'),
        eventcentertop: () => import('../charts/eventsone/eventcentertop.vue'),
        numberevents: () => import('../charts/eventsone/numberevents.vue'),
        imageanalysis: () => import('../charts/eventsone/imageanalysis.vue'),
        activetime: () => import('../charts/eventsone/activetime.vue'),
        eventbottom: () => import('../charts/eventsone/eventbottom.vue'),
    }
}
</script>

<style scoped>
.event-top{
    position: absolute;
    top:1.36rem;
    left:6.1rem;
}
.event-bottom{
    position: absolute;
    top:8.87rem; 
    left:2.4%;   
}

</style>